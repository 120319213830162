import "./App.css";
import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations, MeshTransmissionMaterial } from "@react-three/drei";

class Model extends React.Component {
	//function component inside of class component to know when model has been rendered on the page
	componentDidMount() {
		// console.log("Component has been rendered");
		this.props.onModelLoad();
	}
	componentDidUpdate(prevProps) {
		// console.log("Component has been updated");
		this.props.onModelLoad();
	}

	render() {
		return (
			<ModelContent
				revealMessage={this.props.revealMessage}
				loadingAnimationPlayed={this.props.loadingAnimationPlayed}
				onButtonClick={this.props.onButtonClick}
			/>
		);
	}
}

const ModelContent = ({ ...props }) => {
	const group = useRef();
	const { nodes, materials, animations } = useGLTF("assets/3d-assets/obelisk.glb");
	const { actions } = useAnimations(animations, group);

	useEffect(() => {
		if (props.loadingAnimationPlayed) {
			//timeout to give render 200ms of buffer time so we see the entire animation
			setTimeout(() => {
				actions["BlockDrop"].repetitions = 1;
				actions["BlockDrop"].clampWhenFinished = true;
				actions["BlockDrop"].play();
				actions["Firefly"].play();
			}, 200);
		}
	}, [actions, props.loadingAnimationPlayed]);

	useEffect(() => {
		if (props.revealMessage) {
			actions["TruthAnim"].play();
			actions["TruthAnim"].repetitions = 1;
			actions["TruthAnim"].clampWhenFinished = true;
		}
	}, [actions, props.revealMessage]);

	return (
		<group
			ref={group}
			{...props}
			dispose={null}
			onWheel={(e) => props.onButtonClick("remove_move_indicator")}
			onPointerUp={(e) => props.onButtonClick("remove_move_indicator")}
			onPointerDown={(e) => props.onButtonClick("remove_move_indicator")}
			onPointerOver={(e) => props.onButtonClick("remove_move_indicator")}
			onPointerOut={(e) => props.onButtonClick("remove_move_indicator")}
			onPointerEnter={(e) => props.onButtonClick("remove_move_indicator")}
			onPointerLeave={(e) => props.onButtonClick("remove_move_indicator")}
			onPointerMove={(e) => props.onButtonClick("remove_move_indicator")}
			onPointerMissed={() => props.onButtonClick("remove_move_indicator")}
		>
			<group name="Scene">
				<group name="Main_Holder" position={[0.09, 1.35, 0.13]} rotation={[Math.PI, 0, Math.PI]} scale={0.5}>
					<group name="Truth_holder" position={[-0.05, 0.44, 1.99]} scale={0}>
						<mesh
							name="Curve005"
							castShadow
							receiveShadow
							geometry={nodes.Curve005.geometry}
							material={materials["SVGMat.003"]}
							position={[0.36, 0.22, -1.49]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
						<mesh
							name="Curve007"
							castShadow
							receiveShadow
							geometry={nodes.Curve007.geometry}
							material={materials["SVGMat.003"]}
							position={[0.35, -0.47, -1.49]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
						<mesh
							name="Curve009"
							castShadow
							receiveShadow
							geometry={nodes.Curve009.geometry}
							material={materials["SVGMat.003"]}
							position={[-0.13, -0.44, -1.52]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
						<mesh
							name="Curve011"
							castShadow
							receiveShadow
							geometry={nodes.Curve011.geometry}
							material={materials["SVGMat.003"]}
							position={[0.06, -0.47, -1.53]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
						<mesh
							name="Curve013"
							castShadow
							receiveShadow
							geometry={nodes.Curve013.geometry}
							material={materials["SVGMat.003"]}
							position={[0.53, -0.47, -1.47]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
						<mesh
							name="Curve015"
							castShadow
							receiveShadow
							geometry={nodes.Curve015.geometry}
							material={materials["SVGMat.003"]}
							position={[0.73, 0.22, -1.49]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
						<mesh
							name="Curve003"
							castShadow
							receiveShadow
							geometry={nodes.Curve003.geometry}
							material={materials["SVGMat.003"]}
							position={[0.14, 0.25, -1.5]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
						<mesh
							name="Curve004"
							castShadow
							receiveShadow
							geometry={nodes.Curve004.geometry}
							material={materials["SVGMat.003"]}
							position={[-0.33, 0.22, -1.49]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
						<mesh
							name="Curve006"
							castShadow
							receiveShadow
							geometry={nodes.Curve006.geometry}
							material={materials["SVGMat.003"]}
							position={[-0.33, -0.44, -1.53]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
						<mesh
							name="Curve008"
							castShadow
							receiveShadow
							geometry={nodes.Curve008.geometry}
							material={materials["SVGMat.003"]}
							position={[0.53, 0.22, -1.5]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
						<mesh
							name="Curve010"
							castShadow
							receiveShadow
							geometry={nodes.Curve010.geometry}
							material={materials["SVGMat.003"]}
							position={[-0.53, 0.22, -1.53]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
						<mesh
							name="Curve012"
							castShadow
							receiveShadow
							geometry={nodes.Curve012.geometry}
							material={materials["SVGMat.003"]}
							position={[-0.53, -0.47, -1.52]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
						<mesh
							name="Curve014"
							castShadow
							receiveShadow
							geometry={nodes.Curve014.geometry}
							material={materials["SVGMat.003"]}
							position={[-0.04, 0.22, -1.53]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
						<mesh
							name="Curve016"
							castShadow
							receiveShadow
							geometry={nodes.Curve016.geometry}
							material={materials["SVGMat.003"]}
							position={[-0.71, 0.22, -1.5]}
							rotation={[Math.PI / 2, 0, 0]}
							scale={3.71}
						/>
					</group>
					<group name="Block_Holder001" position={[-0.04, -1.22, 0.02]} rotation={[-Math.PI, 1.11, -Math.PI]}>
						<group name="BlockDrop001" position={[0, 5.52, 0]} scale={0}>
							<mesh name="Cylinder_dmg003" castShadow receiveShadow geometry={nodes.Cylinder_dmg003.geometry} material={materials["OUTSIDE.001"]} />
							<mesh
								name="Cylinder_dmg003_1"
								castShadow
								receiveShadow
								geometry={nodes.Cylinder_dmg003_1.geometry}
								material={materials["Mossy Rock 007_05k.002"]}
							/>
							<mesh
								name="Cylinder_dmg003_2"
								castShadow
								receiveShadow
								geometry={nodes.Cylinder_dmg003_2.geometry}
								material={materials["Mossy Rock 007_05k.003"]}
							/>
							<mesh
								name="Cylinder_dmg003_3"
								castShadow
								receiveShadow
								geometry={nodes.Cylinder_dmg003_3.geometry}
								material={materials["Material.004"]}
							/>
						</group>
					</group>
					<group name="Block_Holder002" position={[-0.04, 0.45, 0.02]} rotation={[-Math.PI, 1.11, -Math.PI]}>
						<group name="Cylinder_dmg001" position={[0, 5.52, 0]} scale={0}>
							<mesh name="Cylinder_dmg001_1" castShadow receiveShadow geometry={nodes.Cylinder_dmg001_1.geometry} material={materials.OUTSIDE} />
							<mesh name="Cylinder_dmg001_2" castShadow receiveShadow geometry={nodes.Cylinder_dmg001_2.geometry} material={materials.INSIDE} />
							<mesh
								name="Cylinder_dmg001_3"
								castShadow
								receiveShadow
								geometry={nodes.Cylinder_dmg001_3.geometry}
								material={materials["Mossy Rock 007_05k"]}
							/>
							<mesh
								name="Cylinder_dmg001_4"
								castShadow
								receiveShadow
								geometry={nodes.Cylinder_dmg001_4.geometry}
								material={materials["Mossy Rock 007_05k.001"]}
							/>
							<mesh
								name="Cylinder_dmg001_5"
								castShadow
								receiveShadow
								geometry={nodes.Cylinder_dmg001_5.geometry}
								material={materials["Material.001"]}
							/>
						</group>
					</group>
					<group name="Block_Holder003" position={[-0.04, 2.13, 0.02]} rotation={[-Math.PI, 1.11, -Math.PI]}>
						<group name="Cylinder_dmg002" position={[0, 5.52, 0]} scale={0}>
							<mesh name="Cylinder_dmg002_1" castShadow receiveShadow geometry={nodes.Cylinder_dmg002_1.geometry} material={materials.OUTSIDE} />
							<mesh
								name="Cylinder_dmg002_2"
								castShadow
								receiveShadow
								geometry={nodes.Cylinder_dmg002_2.geometry}
								material={materials["Mossy Rock 007_05k"]}
							/>
							<mesh
								name="Cylinder_dmg002_3"
								castShadow
								receiveShadow
								geometry={nodes.Cylinder_dmg002_3.geometry}
								material={materials["Mossy Rock 007_05k.001"]}
							/>
							<mesh name="Cylinder_dmg002_4" castShadow receiveShadow geometry={nodes.Cylinder_dmg002_4.geometry} material={materials.Material} />
						</group>
					</group>
					<group name="Ring_Holder001" position={[-0.06, 3.17, 0]}>
						<group name="BlockDrop002" position={[0, 1.82, 0]} scale={0}>
							<mesh
								name="Cylinder002_dmg001"
								castShadow
								receiveShadow
								geometry={nodes.Cylinder002_dmg001.geometry}
								material={materials["Material.002"]}
							/>
							<mesh
								name="Cylinder002_dmg001_1"
								castShadow
								receiveShadow
								geometry={nodes.Cylinder002_dmg001_1.geometry}
								material={materials["Protal-01"]}
							/>
							<pointLight name="Ring_Light001" intensity={0.27} decay={2} color="#3fff04" scale={0} />
						</group>
					</group>
					<pointLight name="StoneLight1" intensity={0.27} decay={2} position={[-0.08, -1.48, 0.95]} rotation={[-Math.PI / 2, 0, 0]} />
					<pointLight name="StoneLight2" intensity={0.27} decay={2} position={[-0.07, 0.14, 0.95]} rotation={[-Math.PI / 2, 0, 0]} />
					<pointLight name="StoneLight3" intensity={0.27} decay={2} position={[-0.06, 1.57, 0.95]} rotation={[-Math.PI / 2, 0, 0]} />
					<mesh
						name="Curve025"
						castShadow
						receiveShadow
						geometry={nodes.Curve025.geometry}
						material={materials["SymbolFly Color"]}
						position={[-2.55, -0.87, -0.29]}
						rotation={[1.57, 0, -1.46]}
						scale={9.41}
					/>
					<mesh
						name="Curve026"
						castShadow
						receiveShadow
						geometry={nodes.Curve026.geometry}
						material={materials["SymbolFly Color"]}
						position={[2.11, -1.06, 1.46]}
						rotation={[1.52, -0.04, 2.33]}
						scale={9.41}
					>
						<pointLight name="Point005" intensity={0.27} decay={2} rotation={[2.76, 0.51, -2.59]} scale={0.11} />
					</mesh>
					<mesh
						name="Curve029"
						castShadow
						receiveShadow
						geometry={nodes.Curve029.geometry}
						material={materials["SymbolFly Color"]}
						position={[-0.95, -0.7, 0.84]}
						rotation={[1.57, 0, -2.01]}
						scale={9.41}
					/>
					<mesh
						name="Curve024"
						castShadow
						receiveShadow
						geometry={nodes.Curve024.geometry}
						material={materials["SymbolFly Color"]}
						position={[0, -0.01, 2.56]}
						rotation={[1.58, 0.11, 3.03]}
						scale={9.41}
					/>
					<mesh
						name="Curve027"
						castShadow
						receiveShadow
						geometry={nodes.Curve027.geometry}
						material={materials["SymbolFly Color"]}
						position={[-2.55, -0.01, -0.29]}
						rotation={[1.55, 0, -1.49]}
						scale={9.41}
					/>
					<mesh
						name="Curve028"
						castShadow
						receiveShadow
						geometry={nodes.Curve028.geometry}
						material={materials["SymbolFly Color"]}
						position={[-0.62, -0.49, 2.5]}
						rotation={[0.82, 0.51, -2.05]}
						scale={9.41}
					>
						<pointLight name="Point004" intensity={0.27} decay={2} rotation={[1.81, -0.22, 2.29]} scale={0.11} />
					</mesh>
					<mesh
						name="Curve030"
						castShadow
						receiveShadow
						geometry={nodes.Curve030.geometry}
						material={materials["SymbolFly Color"]}
						position={[-0.54, 0.76, 1.3]}
						rotation={[1.63, -0.14, -2.72]}
						scale={9.41}
					>
						<pointLight name="Point003" intensity={0.27} decay={2} rotation={[-2.29, 0.79, 2.45]} scale={0.11} />
					</mesh>
					<mesh
						name="Curve031"
						castShadow
						receiveShadow
						geometry={nodes.Curve031.geometry}
						material={materials["SymbolFly Color"]}
						position={[-1.19, -0.51, 1.29]}
						rotation={[1.44, 0.11, -2.27]}
						scale={9.41}
					/>
					<mesh
						name="Cone"
						castShadow
						receiveShadow
						geometry={nodes.Cone.geometry}
						material={materials["Material.006"]}
						position={[0.04, -2, 0.51]}
						rotation={[-3.08, -0.44, -3.12]}
						scale={[0.2, 0.1, 0.2]}
					>
						<MeshTransmissionMaterial thickness={0.01} chromaticAberration={0} anisotropy={3000} clearcoatRoughness={0.2} color={"#00ff00"} />
					</mesh>
					<group name="Watergun001" position={[0.58, -1.97, -2.45]} rotation={[1.58, -0.07, 0.53]} scale={0.09}>
						<mesh name="Frame001" castShadow receiveShadow geometry={nodes.Frame001.geometry} material={materials["Super Soaker Green"]} />
						<mesh name="Frame001_1" castShadow receiveShadow geometry={nodes.Frame001_1.geometry} material={materials["Super Soaker Orange"]} />
						<mesh name="Frame001_2" castShadow receiveShadow geometry={nodes.Frame001_2.geometry} material={materials["Super Soaker Yellow"]} />
						<mesh name="Frame001_3" castShadow receiveShadow geometry={nodes.Frame001_3.geometry} material={materials["Super Soaker White"]} />
					</group>
					<mesh
						name="Corn_AgeMedium_A001"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A001.geometry}
						material={materials.Corn_Shd}
						position={[-3.15, -2.11, 2.38]}
						rotation={[Math.PI, -1.3, Math.PI]}
						scale={0.46}
					/>
					<mesh
						name="Corn_AgeMedium_A002"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A002.geometry}
						material={materials.Corn_Shd}
						position={[-2.61, -2.11, 2.84]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_A003"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A003.geometry}
						material={materials.Corn_Shd}
						position={[-2.5, -2.11, 3.27]}
						rotation={[Math.PI, -1.2, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_A004"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A004.geometry}
						material={materials.Corn_Shd}
						position={[-2.05, -2.11, 3.58]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_A005"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A005.geometry}
						material={materials.Corn_Shd}
						position={[-2.13, -2.11, 3.33]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_A006"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A006.geometry}
						material={materials.Corn_Shd}
						position={[-1.66, -2.11, 3.24]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_A008"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A008.geometry}
						material={materials.Corn_Shd}
						position={[-1.32, -2.11, 3.62]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.48}
					/>
					<mesh
						name="Corn_AgeMedium_A013"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A013.geometry}
						material={materials.Corn_Shd}
						position={[1.5, -2.11, 3.35]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.41}
					/>
					<mesh
						name="Corn_AgeMedium_A014"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A014.geometry}
						material={materials.Corn_Shd}
						position={[1.46, -2.11, 3.59]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_A015"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A015.geometry}
						material={materials.Corn_Shd}
						position={[1.63, -2.11, 3.31]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_A016"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A016.geometry}
						material={materials.Corn_Shd}
						position={[1.94, -2.11, 3.35]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.47}
					/>
					<mesh
						name="Corn_AgeMedium_A017"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A017.geometry}
						material={materials.Corn_Shd}
						position={[1.77, -2.11, 3.34]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_A018"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A018.geometry}
						material={materials.Corn_Shd}
						position={[2.45, -2.11, 3.08]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_A019"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A019.geometry}
						material={materials.Corn_Shd}
						position={[2.78, -2.11, 2.43]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.39}
					/>
					<mesh
						name="Corn_AgeMedium_A020"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A020.geometry}
						material={materials.Corn_Shd}
						position={[3.42, -2.11, 2.28]}
						rotation={[-Math.PI, 0.34, -Math.PI]}
						scale={0.51}
					/>
					<mesh
						name="Corn_AgeMedium_A021"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A021.geometry}
						material={materials.Corn_Shd}
						position={[3.34, -2.11, 1.79]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.48}
					/>
					<mesh
						name="Corn_AgeMedium_A022"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A022.geometry}
						material={materials.Corn_Shd}
						position={[3.25, -2.11, 1.65]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_A023"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A023.geometry}
						material={materials.Corn_Shd}
						position={[3.58, -2.11, 1.51]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_A024"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A024.geometry}
						material={materials.Corn_Shd}
						position={[3.5, -2.11, 1.18]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.46}
					/>
					<mesh
						name="Corn_AgeMedium_A025"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A025.geometry}
						material={materials.Corn_Shd}
						position={[3.98, -2.11, 0.64]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_A026"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A026.geometry}
						material={materials.Corn_Shd}
						position={[3.64, -2.11, 0.28]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.46}
					/>
					<mesh
						name="Corn_AgeMedium_A027"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A027.geometry}
						material={materials.Corn_Shd}
						position={[3.4, -2.11, -1.32]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.39}
					/>
					<mesh
						name="Corn_AgeMedium_A028"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A028.geometry}
						material={materials.Corn_Shd}
						position={[3.75, -2.11, -1.33]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.46}
					/>
					<mesh
						name="Corn_AgeMedium_A029"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A029.geometry}
						material={materials.Corn_Shd}
						position={[3.63, -2.11, -1.1]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.4}
					/>
					<mesh
						name="Corn_AgeMedium_A030"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A030.geometry}
						material={materials.Corn_Shd}
						position={[4.01, -2.11, -0.83]}
						rotation={[Math.PI, -1.02, Math.PI]}
						scale={0.47}
					/>
					<mesh
						name="Corn_AgeMedium_A031"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A031.geometry}
						material={materials.Corn_Shd}
						position={[3.32, -2.11, -1.65]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_A032"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A032.geometry}
						material={materials.Corn_Shd}
						position={[3.36, -2.11, -1.95]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.4}
					/>
					<mesh
						name="Corn_AgeMedium_A033"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A033.geometry}
						material={materials.Corn_Shd}
						position={[3.49, -2.11, -1.64]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_A034"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A034.geometry}
						material={materials.Corn_Shd}
						position={[3.16, -2.11, -2.05]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_A035"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A035.geometry}
						material={materials.Corn_Shd}
						position={[3.08, -2.11, -1.96]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_A036"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A036.geometry}
						material={materials.Corn_Shd}
						position={[3.6, -2.11, -1.96]}
						rotation={[0, -0.87, 0]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_A037"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A037.geometry}
						material={materials.Corn_Shd}
						position={[3.09, -2.11, -2.46]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.37}
					/>
					<mesh
						name="Corn_AgeMedium_A038"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A038.geometry}
						material={materials.Corn_Shd}
						position={[2.78, -2.11, -2.38]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_A039"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A039.geometry}
						material={materials.Corn_Shd}
						position={[3.25, -2.11, -2.14]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.39}
					/>
					<mesh
						name="Corn_AgeMedium_A040"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A040.geometry}
						material={materials.Corn_Shd}
						position={[2.36, -2.11, -3.08]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.44}
					/>
					<mesh
						name="Corn_AgeMedium_A041"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A041.geometry}
						material={materials.Corn_Shd}
						position={[2.6, -2.11, -2.86]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_A042"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A042.geometry}
						material={materials.Corn_Shd}
						position={[2.82, -2.11, -2.92]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_A043"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A043.geometry}
						material={materials.Corn_Shd}
						position={[2.13, -2.11, -3.35]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.4}
					/>
					<mesh
						name="Corn_AgeMedium_A044"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A044.geometry}
						material={materials.Corn_Shd}
						position={[1.96, -2.11, -3.13]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.48}
					/>
					<mesh
						name="Corn_AgeMedium_A045"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A045.geometry}
						material={materials.Corn_Shd}
						position={[2.18, -2.11, -3.22]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_A046"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A046.geometry}
						material={materials.Corn_Shd}
						position={[0.79, -2.11, -3.69]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.46}
					/>
					<mesh
						name="Corn_AgeMedium_A047"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A047.geometry}
						material={materials.Corn_Shd}
						position={[0.25, -2.11, -3.88]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.47}
					/>
					<mesh
						name="Corn_AgeMedium_A048"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A048.geometry}
						material={materials.Corn_Shd}
						position={[-0.57, -2.11, -3.95]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_A049"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A049.geometry}
						material={materials.Corn_Shd}
						position={[-1.08, -2.11, -3.97]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.48}
					/>
					<mesh
						name="Corn_AgeMedium_A050"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A050.geometry}
						material={materials.Corn_Shd}
						position={[-0.73, -2.11, -3.83]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_A051"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A051.geometry}
						material={materials.Corn_Shd}
						position={[-0.92, -2.11, -3.76]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_A052"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A052.geometry}
						material={materials.Corn_Shd}
						position={[-2.29, -2.11, -2.7]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_A053"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A053.geometry}
						material={materials.Corn_Shd}
						position={[-1.95, -2.11, -3.09]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.41}
					/>
					<mesh
						name="Corn_AgeMedium_A054"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A054.geometry}
						material={materials.Corn_Shd}
						position={[-1.58, -2.11, -3.75]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_A055"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A055.geometry}
						material={materials.Corn_Shd}
						position={[-1.8, -2.11, -3.52]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_A056"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A056.geometry}
						material={materials.Corn_Shd}
						position={[-3.08, -2.11, -2.37]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.46}
					/>
					<mesh
						name="Corn_AgeMedium_A057"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A057.geometry}
						material={materials.Corn_Shd}
						position={[-2.85, -2.11, -2.61]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.47}
					/>
					<mesh
						name="Corn_AgeMedium_A058"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A058.geometry}
						material={materials.Corn_Shd}
						position={[-3.1, -2.11, -2.15]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_A059"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A059.geometry}
						material={materials.Corn_Shd}
						position={[-3.34, -2.11, -1.81]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.48}
					/>
					<mesh
						name="Corn_AgeMedium_A060"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A060.geometry}
						material={materials.Corn_Shd}
						position={[-3.75, -2.11, -1.62]}
						rotation={[Math.PI, -1.14, Math.PI]}
						scale={0.41}
					/>
					<mesh
						name="Corn_AgeMedium_A061"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A061.geometry}
						material={materials.Corn_Shd}
						position={[-3.85, -2.11, -0.99]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.4}
					/>
					<mesh
						name="Corn_AgeMedium_A062"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A062.geometry}
						material={materials.Corn_Shd}
						position={[-3.76, -2.11, -1.22]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_A063"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A063.geometry}
						material={materials.Corn_Shd}
						position={[-3.44, -2.11, -1.32]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_A064"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A064.geometry}
						material={materials.Corn_Shd}
						position={[-3.81, -2.11, -0.8]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_A065"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A065.geometry}
						material={materials.Corn_Shd}
						position={[-3.87, -2.11, -0.26]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_A066"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A066.geometry}
						material={materials.Corn_Shd}
						position={[-3.76, -2.11, -0.07]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_A067"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A067.geometry}
						material={materials.Corn_Shd}
						position={[-3.64, -2.11, -0.1]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.47}
					/>
					<mesh
						name="Corn_AgeMedium_A068"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A068.geometry}
						material={materials.Corn_Shd}
						position={[-3.93, -2.11, 0.31]}
						rotation={[0, 1.5, 0]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_A069"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A069.geometry}
						material={materials.Corn_Shd}
						position={[-3.63, -2.11, 0.42]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.47}
					/>
					<mesh
						name="Corn_AgeMedium_A070"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A070.geometry}
						material={materials.Corn_Shd}
						position={[-3.4, -2.11, 1.3]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_A071"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A071.geometry}
						material={materials.Corn_Shd}
						position={[-3.23, -2.11, 1.7]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.44}
					/>
					<mesh
						name="Corn_AgeMedium_A072"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A072.geometry}
						material={materials.Corn_Shd}
						position={[-3.37, -2.11, 2.02]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_A073"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_A073.geometry}
						material={materials.Corn_Shd}
						position={[-3.38, -2.11, 1.57]}
						rotation={[Math.PI, -0.58, Math.PI]}
						scale={0.47}
					/>
					<mesh
						name="Corn_AgeMedium_B001"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B001.geometry}
						material={materials.Corn_Shd}
						position={[-2.98, -2.11, 2.5]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.41}
					/>
					<mesh
						name="Corn_AgeMedium_B002"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B002.geometry}
						material={materials.Corn_Shd}
						position={[-2.36, -2.11, 3.06]}
						rotation={[0, -0.86, 0]}
						scale={0.48}
					/>
					<mesh
						name="Corn_AgeMedium_B003"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B003.geometry}
						material={materials.Corn_Shd}
						position={[-1.96, -2.11, 3.11]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.46}
					/>
					<mesh
						name="Corn_AgeMedium_B004"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B004.geometry}
						material={materials.Corn_Shd}
						position={[-1.52, -2.11, 3.57]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.46}
					/>
					<mesh
						name="Corn_AgeMedium_B005"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B005.geometry}
						material={materials.Corn_Shd}
						position={[-1.19, -2.11, 3.49]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.37}
					/>
					<mesh
						name="Corn_AgeMedium_B009"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B009.geometry}
						material={materials.Corn_Shd}
						position={[1.32, -2.11, 3.74]}
						rotation={[Math.PI, -0.48, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_B011"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B011.geometry}
						material={materials.Corn_Shd}
						position={[1.63, -2.11, 3.49]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_B012"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B012.geometry}
						material={materials.Corn_Shd}
						position={[2.04, -2.11, 3.15]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.4}
					/>
					<mesh
						name="Corn_AgeMedium_B013"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B013.geometry}
						material={materials.Corn_Shd}
						position={[2.27, -2.11, 3.23]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_B014"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B014.geometry}
						material={materials.Corn_Shd}
						position={[2.86, -2.11, 2.25]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.39}
					/>
					<mesh
						name="Corn_AgeMedium_B015"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B015.geometry}
						material={materials.Corn_Shd}
						position={[2.52, -2.11, 2.72]}
						rotation={[0, -0.04, 0]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_B016"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B016.geometry}
						material={materials.Corn_Shd}
						position={[3.58, -2.11, 1.71]}
						rotation={[Math.PI, -0.27, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_B018"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B018.geometry}
						material={materials.Corn_Shd}
						position={[3.63, -2.11, 1.31]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.48}
					/>
					<mesh
						name="Corn_AgeMedium_B019"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B019.geometry}
						material={materials.Corn_Shd}
						position={[3.86, -2.11, -1.09]}
						rotation={[Math.PI, -0.56, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_B020"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B020.geometry}
						material={materials.Corn_Shd}
						position={[3.67, -2.11, 0.48]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.47}
					/>
					<mesh
						name="Corn_AgeMedium_B021"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B021.geometry}
						material={materials.Corn_Shd}
						position={[3.84, -2.11, -0.52]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_B022"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B022.geometry}
						material={materials.Corn_Shd}
						position={[3.85, -2.11, -0.18]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.44}
					/>
					<mesh
						name="Corn_AgeMedium_B023"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B023.geometry}
						material={materials.Corn_Shd}
						position={[3.7, -2.11, -0.69]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.39}
					/>
					<mesh
						name="Corn_AgeMedium_B024"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B024.geometry}
						material={materials.Corn_Shd}
						position={[3.6, -2.11, -1.47]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.37}
					/>
					<mesh
						name="Corn_AgeMedium_B025"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B025.geometry}
						material={materials.Corn_Shd}
						position={[2.94, -2.11, -2.4]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.39}
					/>
					<mesh
						name="Corn_AgeMedium_B026"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B026.geometry}
						material={materials.Corn_Shd}
						position={[3.24, -2.11, -2.29]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.46}
					/>
					<mesh
						name="Corn_AgeMedium_B027"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B027.geometry}
						material={materials.Corn_Shd}
						position={[2.64, -2.11, -2.38]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_B028"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B028.geometry}
						material={materials.Corn_Shd}
						position={[2.14, -2.11, -3.11]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.39}
					/>
					<mesh
						name="Corn_AgeMedium_B029"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B029.geometry}
						material={materials.Corn_Shd}
						position={[2.32, -2.11, -2.76]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_B030"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B030.geometry}
						material={materials.Corn_Shd}
						position={[2.38, -2.11, -3.19]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.44}
					/>
					<mesh
						name="Corn_AgeMedium_B031"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B031.geometry}
						material={materials.Corn_Shd}
						position={[1.08, -2.11, -3.98]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_B032"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B032.geometry}
						material={materials.Corn_Shd}
						position={[1.67, -2.11, -3.7]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.51}
					/>
					<mesh
						name="Corn_AgeMedium_B033"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B033.geometry}
						material={materials.Corn_Shd}
						position={[1.18, -2.11, -3.34]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_B034"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B034.geometry}
						material={materials.Corn_Shd}
						position={[0.99, -2.11, -3.52]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_B035"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B035.geometry}
						material={materials.Corn_Shd}
						position={[0.79, -2.11, -3.82]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_B036"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B036.geometry}
						material={materials.Corn_Shd}
						position={[0.27, -2.11, -4.11]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_B037"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B037.geometry}
						material={materials.Corn_Shd}
						position={[0.06, -2.11, -3.62]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.46}
					/>
					<mesh
						name="Corn_AgeMedium_B038"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B038.geometry}
						material={materials.Corn_Shd}
						position={[-0.39, -2.11, -3.65]}
						rotation={[Math.PI, -0.91, Math.PI]}
						scale={0.41}
					/>
					<mesh
						name="Corn_AgeMedium_B039"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B039.geometry}
						material={materials.Corn_Shd}
						position={[-0.32, -2.11, -3.94]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_B040"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B040.geometry}
						material={materials.Corn_Shd}
						position={[-2.03, -2.11, -3.43]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_B041"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B041.geometry}
						material={materials.Corn_Shd}
						position={[-2.66, -2.11, -3.01]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_B042"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B042.geometry}
						material={materials.Corn_Shd}
						position={[-2.13, -2.11, -3.26]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.48}
					/>
					<mesh
						name="Corn_AgeMedium_B043"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B043.geometry}
						material={materials.Corn_Shd}
						position={[-2.98, -2.11, -2.11]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.4}
					/>
					<mesh
						name="Corn_AgeMedium_B044"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B044.geometry}
						material={materials.Corn_Shd}
						position={[-3.3, -2.11, -2.01]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_B045"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B045.geometry}
						material={materials.Corn_Shd}
						position={[-3.33, -2.11, -1.49]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_B046"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B046.geometry}
						material={materials.Corn_Shd}
						position={[-3.7, -2.11, -1.44]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_B047"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B047.geometry}
						material={materials.Corn_Shd}
						position={[-3.51, -2.11, -1]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_B048"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B048.geometry}
						material={materials.Corn_Shd}
						position={[-3.81, -2.11, -0.59]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.39}
					/>
					<mesh
						name="Corn_AgeMedium_B049"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B049.geometry}
						material={materials.Corn_Shd}
						position={[-3.77, -2.11, 0.35]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.46}
					/>
					<mesh
						name="Corn_AgeMedium_B050"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B050.geometry}
						material={materials.Corn_Shd}
						position={[-3.96, -2.11, 0.08]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_B051"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B051.geometry}
						material={materials.Corn_Shd}
						position={[-3.68, -2.11, 0.09]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.41}
					/>
					<mesh
						name="Corn_AgeMedium_B052"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B052.geometry}
						material={materials.Corn_Shd}
						position={[-3.67, -2.11, 1.23]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.48}
					/>
					<mesh
						name="Corn_AgeMedium_B053"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B053.geometry}
						material={materials.Corn_Shd}
						position={[-3.31, -2.11, 1.49]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_B054"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B054.geometry}
						material={materials.Corn_Shd}
						position={[-3.77, -2.11, 1.09]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_B055"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B055.geometry}
						material={materials.Corn_Shd}
						position={[-3.14, -2.11, 2.03]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_B056"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_B056.geometry}
						material={materials.Corn_Shd}
						position={[-3.24, -2.11, 1.87]}
						rotation={[Math.PI, -1.53, Math.PI]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_C001"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C001.geometry}
						material={materials.Corn_Shd}
						position={[-2.91, -2.11, 2.63]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_C002"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C002.geometry}
						material={materials.Corn_Shd}
						position={[-2.68, -2.11, 2.61]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_C003"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C003.geometry}
						material={materials.Corn_Shd}
						position={[-2.14, -2.11, 3.08]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.41}
					/>
					<mesh
						name="Corn_AgeMedium_C004"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C004.geometry}
						material={materials.Corn_Shd}
						position={[-1.72, -2.11, 3.58]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_C005"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C005.geometry}
						material={materials.Corn_Shd}
						position={[-1.8, -2.11, 3.33]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.47}
					/>
					<mesh
						name="Corn_AgeMedium_C012"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C012.geometry}
						material={materials.Corn_Shd}
						position={[1.39, -2.11, 3.49]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.44}
					/>
					<mesh
						name="Corn_AgeMedium_C013"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C013.geometry}
						material={materials.Corn_Shd}
						position={[1.31, -2.11, 3.39]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_C016"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C016.geometry}
						material={materials.Corn_Shd}
						position={[1.94, -2.11, 3.07]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.47}
					/>
					<mesh
						name="Corn_AgeMedium_C017"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C017.geometry}
						material={materials.Corn_Shd}
						position={[2.03, -2.11, 3.41]}
						rotation={[0, -0.35, 0]}
						scale={0.51}
					/>
					<mesh
						name="Corn_AgeMedium_C018"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C018.geometry}
						material={materials.Corn_Shd}
						position={[2.67, -2.11, 2.52]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_C019"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C019.geometry}
						material={materials.Corn_Shd}
						position={[3.07, -2.11, 2.35]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.48}
					/>
					<mesh
						name="Corn_AgeMedium_C020"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C020.geometry}
						material={materials.Corn_Shd}
						position={[2.84, -2.11, 2.59]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.39}
					/>
					<mesh
						name="Corn_AgeMedium_C021"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C021.geometry}
						material={materials.Corn_Shd}
						position={[3.09, -2.11, 2.13]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.47}
					/>
					<mesh
						name="Corn_AgeMedium_C023"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C023.geometry}
						material={materials.Corn_Shd}
						position={[3.44, -2.11, 1.3]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_C024"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C024.geometry}
						material={materials.Corn_Shd}
						position={[3.74, -2.11, 0.99]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_C025"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C025.geometry}
						material={materials.Corn_Shd}
						position={[3.76, -2.11, 0.05]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_C026"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C026.geometry}
						material={materials.Corn_Shd}
						position={[3.64, -2.11, 0.08]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_C027"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C027.geometry}
						material={materials.Corn_Shd}
						position={[3.77, -2.11, 0.38]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.37}
					/>
					<mesh
						name="Corn_AgeMedium_C028"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C028.geometry}
						material={materials.Corn_Shd}
						position={[3.9, -2.11, 0.67]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_C029"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C029.geometry}
						material={materials.Corn_Shd}
						position={[3.63, -2.11, 0.61]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_C030"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C030.geometry}
						material={materials.Corn_Shd}
						position={[3.8, -2.11, 0.78]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_C031"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C031.geometry}
						material={materials.Corn_Shd}
						position={[3.79, -2.11, -1.09]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_C032"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C032.geometry}
						material={materials.Corn_Shd}
						position={[3.19, -2.11, -1.79]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_C033"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C033.geometry}
						material={materials.Corn_Shd}
						position={[3.36, -2.11, -1.51]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_C034"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C034.geometry}
						material={materials.Corn_Shd}
						position={[3, -2.11, -2.14]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_C035"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C035.geometry}
						material={materials.Corn_Shd}
						position={[1.92, -2.11, -3.46]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_C036"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C036.geometry}
						material={materials.Corn_Shd}
						position={[1.66, -2.11, -3.26]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_C037"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C037.geometry}
						material={materials.Corn_Shd}
						position={[1.19, -2.11, -3.51]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_C038"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C038.geometry}
						material={materials.Corn_Shd}
						position={[0.8, -2.11, -3.56]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_C039"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C039.geometry}
						material={materials.Corn_Shd}
						position={[0.98, -2.11, -3.86]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.41}
					/>
					<mesh
						name="Corn_AgeMedium_C040"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C040.geometry}
						material={materials.Corn_Shd}
						position={[1.21, -2.11, -3.76]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_C041"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C041.geometry}
						material={materials.Corn_Shd}
						position={[1.43, -2.11, -3.71]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_C042"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C042.geometry}
						material={materials.Corn_Shd}
						position={[0.62, -2.11, -3.64]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.37}
					/>
					<mesh
						name="Corn_AgeMedium_C043"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C043.geometry}
						material={materials.Corn_Shd}
						position={[0.48, -2.11, -3.68]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_C044"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C044.geometry}
						material={materials.Corn_Shd}
						position={[-0.68, -2.11, -3.71]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_C045"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C045.geometry}
						material={materials.Corn_Shd}
						position={[-1.44, -2.11, -3.19]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.46}
					/>
					<mesh
						name="Corn_AgeMedium_C046"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C046.geometry}
						material={materials.Corn_Shd}
						position={[-0.82, -2.11, -3.6]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_C047"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C047.geometry}
						material={materials.Corn_Shd}
						position={[-0.96, -2.11, -3.59]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_C048"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C048.geometry}
						material={materials.Corn_Shd}
						position={[-1.25, -2.11, -3.68]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_C049"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C049.geometry}
						material={materials.Corn_Shd}
						position={[-1.6, -2.11, -3.64]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.41}
					/>
					<mesh
						name="Corn_AgeMedium_C050"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C050.geometry}
						material={materials.Corn_Shd}
						position={[-2.13, -2.11, -3.01]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_C051"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C051.geometry}
						material={materials.Corn_Shd}
						position={[-2.78, -2.11, -2.45]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.4}
					/>
					<mesh
						name="Corn_AgeMedium_C052"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C052.geometry}
						material={materials.Corn_Shd}
						position={[-3.18, -2.11, -2.39]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_C053"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C053.geometry}
						material={materials.Corn_Shd}
						position={[-3.45, -2.11, -1.93]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.47}
					/>
					<mesh
						name="Corn_AgeMedium_C054"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C054.geometry}
						material={materials.Corn_Shd}
						position={[-3.35, -2.11, -2.14]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_C055"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C055.geometry}
						material={materials.Corn_Shd}
						position={[-3.25, -2.11, -1.67]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.38}
					/>
					<mesh
						name="Corn_AgeMedium_C056"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C056.geometry}
						material={materials.Corn_Shd}
						position={[-3.12, -2.11, -1.97]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.39}
					/>
					<mesh
						name="Corn_AgeMedium_C057"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C057.geometry}
						material={materials.Corn_Shd}
						position={[-3.21, -2.11, -2.2]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.39}
					/>
					<mesh
						name="Corn_AgeMedium_C058"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C058.geometry}
						material={materials.Corn_Shd}
						position={[-3.21, -2.11, -1.86]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.48}
					/>
					<mesh
						name="Corn_AgeMedium_C059"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C059.geometry}
						material={materials.Corn_Shd}
						position={[-3.48, -2.11, -1.73]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_C060"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C060.geometry}
						material={materials.Corn_Shd}
						position={[-3.62, -2.11, -1.12]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_C061"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C061.geometry}
						material={materials.Corn_Shd}
						position={[-3.67, -2.11, -0.5]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.49}
					/>
					<mesh
						name="Corn_AgeMedium_C062"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C062.geometry}
						material={materials.Corn_Shd}
						position={[-3.97, -2.11, -0.22]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.5}
					/>
					<mesh
						name="Corn_AgeMedium_C063"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C063.geometry}
						material={materials.Corn_Shd}
						position={[-3.89, -2.11, -0.04]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.39}
					/>
					<mesh
						name="Corn_AgeMedium_C064"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C064.geometry}
						material={materials.Corn_Shd}
						position={[-3.69, -2.11, 0.23]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.42}
					/>
					<mesh
						name="Corn_AgeMedium_C065"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C065.geometry}
						material={materials.Corn_Shd}
						position={[-3.75, -2.11, 0.91]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.44}
					/>
					<mesh
						name="Corn_AgeMedium_C066"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C066.geometry}
						material={materials.Corn_Shd}
						position={[-3.5, -2.11, 1.38]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_C067"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C067.geometry}
						material={materials.Corn_Shd}
						position={[-3.39, -2.11, 1.73]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.45}
					/>
					<mesh
						name="Corn_AgeMedium_C068"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C068.geometry}
						material={materials.Corn_Shd}
						position={[-3.12, -2.11, 1.88]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.39}
					/>
					<mesh
						name="Corn_AgeMedium_C069"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C069.geometry}
						material={materials.Corn_Shd}
						position={[-3.41, -2.11, 1.87]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.46}
					/>
					<mesh
						name="Corn_AgeMedium_C070"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C070.geometry}
						material={materials.Corn_Shd}
						position={[-3.58, -2.11, 1.45]}
						rotation={[Math.PI, -1.33, Math.PI]}
						scale={0.43}
					/>
					<mesh
						name="Corn_AgeMedium_C071"
						castShadow
						receiveShadow
						geometry={nodes.Corn_AgeMedium_C071.geometry}
						material={materials.Corn_Shd}
						position={[-3.97, -2.11, 0.99]}
						rotation={[0, -1.04, 0]}
						scale={0.41}
					/>
					<mesh
						name="Light_Cone"
						castShadow
						receiveShadow
						geometry={nodes.Light_Cone.geometry}
						material={materials["Protal-01"]}
						position={[-0.04, 0.32, 0.48]}
						scale={0}
					/>
					<mesh
						name="Circle001"
						castShadow
						receiveShadow
						geometry={nodes.Circle001.geometry}
						material={materials["Material.003"]}
						position={[0, -1.91, 0]}
						rotation={[-Math.PI, 0.99, -Math.PI]}
						scale={3.3}
					/>
					<mesh
						name="Rock"
						castShadow
						receiveShadow
						geometry={nodes.Rock.geometry}
						material={materials["RocksCollection.001"]}
						position={[-0.83, -2.08, 2.77]}
						rotation={[-Math.PI, 0.99, -Math.PI]}
						scale={2.34}
					/>
					<mesh
						name="Rock001"
						castShadow
						receiveShadow
						geometry={nodes.Rock001.geometry}
						material={materials["RocksCollection.001"]}
						position={[-1.29, -2.01, 0.47]}
						rotation={[-Math.PI, 0.99, -Math.PI]}
						scale={3.35}
					/>
					<mesh
						name="Rock002"
						castShadow
						receiveShadow
						geometry={nodes.Rock002.geometry}
						material={materials["RocksCollection.001"]}
						position={[1.72, -1.94, 0.89]}
						rotation={[-Math.PI, 0.99, -Math.PI]}
						scale={2.26}
					/>
					<mesh
						name="Rock003"
						castShadow
						receiveShadow
						geometry={nodes.Rock003.geometry}
						material={materials["RocksCollection.001"]}
						position={[2.02, -2.01, -1.27]}
						rotation={[-Math.PI, 0.67, -Math.PI]}
						scale={4.19}
					/>
					<mesh
						name="Rock004"
						castShadow
						receiveShadow
						geometry={nodes.Rock004.geometry}
						material={materials["RocksCollection.001"]}
						position={[-1.67, -2.01, -0.77]}
						rotation={[-Math.PI, 0.99, -Math.PI]}
						scale={4.76}
					/>
					<mesh
						name="Rock005"
						castShadow
						receiveShadow
						geometry={nodes.Rock005.geometry}
						material={materials["RocksCollection.001"]}
						position={[0.29, -2.01, -2]}
						rotation={[-Math.PI, 0.99, -Math.PI]}
						scale={-4.09}
					/>
					<group name="Grass_Basic-dry_A_spring-summer002" position={[-2.31, -2.01, 0.21]} rotation={[-Math.PI, 0.21, -Math.PI]} scale={1.33}>
						<mesh
							name="Grass_Basic-dry_A_spring-summer"
							castShadow
							receiveShadow
							geometry={nodes["Grass_Basic-dry_A_spring-summer"].geometry}
							material={materials.Grass_bqm}
						/>
						<mesh
							name="Grass_Basic-dry_A_spring-summer_1"
							castShadow
							receiveShadow
							geometry={nodes["Grass_Basic-dry_A_spring-summer_1"].geometry}
							material={materials.Weed_bqm}
						/>
					</group>
				</group>
			</group>
		</group>
	);
};

export default Model;

// <mesh
// 	name="Cone"
// 	castShadow
// 	receiveShadow
// 	geometry={nodes.Cone.geometry}
// 	material={materials["Material.006"]}
// 	position={[0.15, -0.52, 3.62]}
// 	rotation={[0.04, 0.21, 0.45]}
// 	scale={[0.52, 0.26, 0.52]}
// >
// 	<MeshTransmissionMaterial thickness={0.01} chromaticAberration={0} anisotropy={3000} clearcoatRoughness={0.2} color={"#00ff00"} />
// </mesh>
