import "../App.css";
import "typeface-cinzel";
import parse from "html-react-parser";
import { useEffect, useState } from "react";

function Modal({ title, copy, buttonText, onButtonClick, action }) {
	const [opacity, setOpacity] = useState(0);
	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 1500);
	});

	return (
		<div className="modal" style={{ opacity: opacity }}>
			<h1>{title}</h1>
			<p>{parse(copy)}</p>
			<div className={`button`}>
				<div
					className="text"
					onClick={() => {
						onButtonClick(action);
					}}
				>
					<p>{buttonText}</p>
				</div>
			</div>
		</div>
	);
}

export default Modal;
