import "./App.css";
import "typeface-cinzel";
import { Suspense, useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import Model from "./model-class";
import Modal from "./components/modal";
import EndScreen from "./components/end-screen";
import { Loader, SkyBox, CameraRig } from "./components/model-components";

function App() {
	const [showModel, setShowModel] = useState(false);
	const [modalToShow, setModalToShow] = useState("none");
	const [revealMessage, setRevealMessage] = useState(false);
	const [modelLoaded, setModelLoaded] = useState(false);
	const [loadingAnimationPlayed, setLoadingAnimationPlayed] = useState(false);
	const [modelInteracted, setModelInteracted] = useState(false);
	const [indicatorOpacity, setIndicatorOpacity] = useState(0);

	useEffect(() => {
		// console.log(`run use effect: \n modelLoaded: ${modelLoaded}, loadingAnimationPlayed: ${loadingAnimationPlayed}`);
		setTimeout(() => {
			setLoadingAnimationPlayed(true);
		}, 6000);
		if (modelLoaded && loadingAnimationPlayed) {
			setShowModel(true);
			// timeout to allow model block drop animation to complete
			setTimeout(() => {
				setIndicatorOpacity(1);
				setModalToShow("start");
			}, 7700);
		}
	}, [showModel, modelLoaded, loadingAnimationPlayed]);

	const onModelLoad = () => {
		//depended on suspense and model-class.js
		if (loadingAnimationPlayed) setModelLoaded(true);
	};

	const onButtonClick = (action) => {
		switch (action) {
			case "reveal_message":
				setRevealMessage(true);
				setModalToShow("none");
				setTimeout(() => {
					setModalToShow("middle");
				}, 2500);
				break;
			case "show_end_screen":
				setModalToShow("end-screen");
				onButtonClick("remove_move_indicator");
				break;
			case "reset":
				setModalToShow("start");
				setRevealMessage(false);
				break;
			case "remove_move_indicator":
				setIndicatorOpacity(0);
				setTimeout(() => {
					setModelInteracted(true);
				}, 500);
				break;

			default:
				break;
		}
	};

	return (
		<div className="App">
			{showModel ? (
				<div className={`touch-indicator ${modelInteracted ? "hidden" : "visible"}`} style={{ opacity: indicatorOpacity }}>
					<img src="assets/images/explorehand.svg" alt="explore the model icon" />
				</div>
			) : null}
			{modalToShow === "start" ? (
				<Modal
					title={"The Tower"}
					copy={"The hidden message is near. <br/> Can you see it?"}
					buttonText={"REVEAL"}
					onButtonClick={onButtonClick}
					action={"reveal_message"}
				/>
			) : null}
			{modalToShow === "middle" ? (
				<Modal
					title={"Success!"}
					copy={"Add the hidden message <br/> to your Quest Card."}
					buttonText={"CONTINUE"}
					onButtonClick={onButtonClick}
					action={"show_end_screen"}
				/>
			) : null}
			{modalToShow === "end-screen" ? <EndScreen /> : null}
			{modalToShow !== "end-screen" ? (
				<Canvas style={{ width: "100vw", height: "100vh" }} shadows={true} camera={{ position: [0, 1, 9] }}>
					{!loadingAnimationPlayed ? <Loader /> : null}
					<Suspense fallback={<Loader />}>
						<Model
							revealMessage={revealMessage}
							onModelLoad={onModelLoad}
							loadingAnimationPlayed={loadingAnimationPlayed}
							onButtonClick={onButtonClick}
						/>
					</Suspense>
					{/* <SkyBox /> */}
					<OrbitControls
						minPolarAngle={Math.PI / 3} // limit up
						maxPolarAngle={Math.PI - Math.PI / 1.8} // limit down
						minDistance={2.7}
						maxDistance={25}
					/>
					{revealMessage ? <CameraRig /> : null}
				</Canvas>
			) : null}
		</div>
	);
}

export default App;
