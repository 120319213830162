import "../App.css";
import "typeface-cinzel";
import { useEffect, useState } from "react";

function EndScreen() {
	const [opacity, setOpacity] = useState(0);
	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 300);
	});

	return (
		<div className="end-screen" style={{ opacity: opacity }}>
			<div>
				<h1>
					Mystery <br /> Solved
				</h1>
				<p>FieldView™ and ForGround by Bayer can help decode the mystery of finding more profit in your acres.</p>
			</div>
			<div className="logo-and-link">
				<a href="https://climate.com/getting-started" target="_blank" rel="noreferrer">
					<img src="assets/images/fieldview-logo.png" alt="Fieldview logo." />
					<div className="button-text ">learn more</div>
				</a>
			</div>
			<div className="logo-and-link">
				<a href="https://trial.carbon.bayer.us/" target="_blank" rel="noreferrer">
					<img src="assets/images/forGround-bayer-logo.png" alt="ForGround Bayer logo." />
					<div className="button-text ">learn more</div>
				</a>
			</div>
		</div>
	);
}

export default EndScreen;
