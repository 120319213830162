import { useThree, useFrame } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import { CubeTextureLoader } from "three";

function Loader() {
	return (
		<Html center>
			<div className="image-bg">
				<img src="assets/images/ObeliskAnim.svg" alt="loading model animation" id="model-load-svg" />
			</div>
		</Html>
	);
}

function SkyBox() {
	const { scene } = useThree();

	const loader = new CubeTextureLoader();

	const skybox = loader.setPath("assets/images/skybox/").load([
		"Right.jpg", // right
		"Left.jpg", // left
		"Top.jpg", // top
		"Bottom.jpg", // bottom
		"Front.jpg", // front
		"Back.jpg", //back
	]);
	scene.background = skybox;
	return null;
}

function CameraRig() {
	useFrame((state) => {
		state.camera.position.lerp({ x: 0, y: 1.5, z: 4.5 }, 0.08);
		state.camera.lookAt(0, 1.5, 0);
	});
}

export { Loader, SkyBox, CameraRig };
